<template>
    <div style="display: flex;">
        <div class="pet-detail-box">
            <div class="shop_box">
                <div style="text-align: center">
                    <p style="padding-bottom: 20px;">店铺封面图</p>
                    <el-upload
                            class="cover-uploader"
                            action="/officialWebsiteMgr/resource/file/addImage"
                            :show-file-list="false"
                            :on-success="handleSuccess"
                            ref="upload"
                    >
                        <img v-if="!isUpload" :src="formatImg(showImg)" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div>
                    <el-input v-model="shopName" placeholder="店铺名称"></el-input>
                </div>
                <div>
                    <el-input v-model="shopPhone" placeholder="联系电话"></el-input>
                </div>
                <div class="addressDiv">
                    选择地区：
                    <el-select v-model="name1" placeholder="请选择" @change="selectOne">
                        <el-option
                                v-for="item in select1"
                                :key="item.pCode"
                                :label="item.areaName"
                                :value="item">
                        </el-option>
                    </el-select>
                    <el-select v-model="name2" placeholder="请选择" @change="selectTwo" v-if="isSelect">
                        <el-option
                                v-for="item in select2"
                                :key="item.pCode"
                                :label="item.areaName"
                                :value="item">
                        </el-option>
                    </el-select>
                    <el-select v-model="name3" placeholder="请选择" @change="selectThree" v-if="isSelect">
                        <el-option
                                v-for="item in select3"
                                :key="item.pCode"
                                :label="item.areaName"
                                :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <el-input v-model="address" placeholder="详细地址"></el-input>
                </div>
                <div>
                    <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入店铺内容"
                            v-model="shopDesc">
                    </el-input>
                </div>
                <div>
                    <p style="padding-bottom: 20px;">店铺轮播图</p>
                    <el-upload
                            :show-file-list="true"
                            action="/officialWebsiteMgr/resource/file/addImage"
                            list-type="picture-card"
                            :on-success="successImg"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            ref="upload">
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                </div>
            </div>
        </div>
        <div class="pet_fun">
            <div>
                <!-- <el-button @click="importTemp">导入模板</el-button> -->
                <el-button type="warning" @click="warning">清空</el-button>
                <el-button type="primary" @click="addShop">保存</el-button>
                <el-button type="info" @click="goReturn">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import instance from '@/services/instance';
    import fetch from "@/services/service"

    export default {
        name: "editShop",
        data() {
            return {
                isUpload:true,
                shopName:"",//店铺名称
                shopPhone:"",//手机号
                address:"",//详细地址
                shopDesc:"",//介绍
                showImg:"",//封面图
                showBanner:[],//轮播图
                fileList:[],
                isSelect:false,
                select1:[],
                select2:[],
                select3:[],
                value1:"",
                value2:"",
                value3:"",
                name1:"",
                name2:"",
                name3:"",
            };
        },
        created(){
            this.addType(1,1)
            this.shopId=this.$route.params.id;
            this.getShop();
        },
        methods:{
            handleSuccess(res) {
                if (res.code == 0) {
                    this.isUpload=false;
                    this.showImg = res.data;
                }
            },
            successImg(res){
                if (res.code == 0) {
                    this.showBanner.push(res.data);
                }
            },
            handleRemove(file) {
                for(var i=0;i<this.showBanner.length;i++){
                    if(file.response){
                        if(this.showBanner[i]==file.response.data){
                            this.showBanner.splice(i,1);
                        }
                    }else{
                        if(this.formatImg(this.showBanner[i])==file.url){
                            this.showBanner.splice(i,1);
                        }
                    }

                }
            },
            formatImg(url){
                return "/officialWebsiteMgr/resource/file/getImage?fileName="+url;
            },
            selectOne(e){
                this.name1=e.areaName;
                this.value1=e.gbCode;
                this.name2="请选择";
                this.value2="";
                this.name3="请选择";
                this.value3="";
                this.isSelect=true;
                this.addType(2,this.value1)
            },
            selectTwo(e){
                this.name2=e.areaName;
                this.value2=e.gbCode;
                this.name3="请选择";
                this.value3="";
                this.addType(3,this.value2)
            },
            selectThree(e){
                this.name3=e.areaName;
                this.value3=e.gbCode;
            },
            addType(type,tid){
                fetch
                    .post('/officialWebsiteMgr/resource/area/list',{pCode: tid})
                    .then((res) => {
                        if (res.code == 0) {
                            if(type==1){
                                this.select1=res.data;
                            }else if(type==2){
                                this.select2=res.data;
                            }else if(type==3){
                                this.select3=res.data;
                            }
                        }
                    })
            },
            getShop(){
                fetch
                    .post('/officialWebsiteMgr/resource/shop/userDesc',{ shopId: this.shopId })
                    .then((res) => {
                        if (res.code == 0) {
                            let data=res.data;
                            this.showImg=data.showImg;
                            this.shopName=data.shopName;
                            this.shopPhone=data.shopPhone;
                            this.address=data.address;
                            this.shopDesc=data.shopDesc;
                            this.value1=data.oneAreaId;
                            this.value2=data.twoAreaId;
                            this.value3=data.threeAreaId;
                            this.name1=data.area;

                            if(data.showBanner && data.showBanner!=""){
                                var getImg=data.showBanner.split(",");
                                this.showBanner=getImg;//发送数据
                                for(var i=0;i<getImg.length;i++){
                                    this.fileList.push({url:this.formatImg(getImg[i])})
                                }
                            }
                            console.log(this.showBanner)
                            this.isUpload=false;

                        } else if (res.code == 999) {
                            this.$message.error(res.msg);
                        }
                    })
            },
            addShop(){
                if(this.showImg==""){
                    this.$message.error("请上传封面图");
                    return false;
                }
                if(this.shopName==""){
                    this.$message.error("请输入店铺名称");
                    return false;
                }
                if(this.shopPhone==""){
                    this.$message.error("请输入联系方式");
                    return false;
                }
                if(this.value1==""){
                    this.$message.error("请选择城市");
                    return false;
                }
                if(this.address==""){
                    this.$message.error("请输入详细地址");
                    return false;
                }
                if(this.shopDesc==""){
                    this.$message.error("请输入店铺内容");
                    return false;
                }
                var value=this.value1;
                if(this.value3!="" && this.value3!=null){
                    value=this.value3;
                }
                if(this.showBanner && this.showBanner.length>0){
                    this.showBanner=this.showBanner.join(",")
                }

                let sendData={
                    shopId: this.shopId,
                    areaId: value,
                    shopName: this.shopName,
                    shopPhone: this.shopPhone,
                    address: this.address,
                    shopDesc: this.shopDesc,
                    showImg: this.showImg,
                    showBanner: this.showBanner
                }
                console.log(sendData)

                fetch
                    .post('/officialWebsiteMgr/resource/shop/edit',sendData)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: '店铺提交成功！',
                                type: 'success'
                            });
                            this.$router.push({
                                path: '/mySpace/myShop',
                            })
                        } else if (res.code == 999) {
                            this.$message.error(res.msg);
                        }
                    })
            },
            goReturn(){
                this.$router.go(-1);
            },
            warning(){
                this.showImg="";
                this.shopName="";
                this.shopPhone="";
                this.address="";
                this.shopDesc="";
                this.showBanner=[];
                this.value1="";
                this.value2="";
                this.value3="";
                this.name1="";
                this.name2="";
                this.name3="";
                this.isUpload=true;
                this.fileList=[];
            }
        }
    }
</script>

<style  lang="scss">
    .shop_box{
        padding: 10px 20px 20px 30px;
    }
    .shop_box>div{
        margin-bottom: 20px;
    }
    .cover-uploader{
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-upload:hover {
            border-color: #409EFF;
        }
    }
    .addressDiv{
        display: flex;
        align-items: center;
        div{
            flex:1;
        }
    }
</style>